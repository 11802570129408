import http from '../../../http/axiosConfig';
import notifyConfigMixin from '../../notifyConfigMixin';

export default {
  mixins: [notifyConfigMixin],
  data() {
    return {
      schemaObject: null,

      showViewer: false,
      showEdit: false,
      showNew: false,

      stuffViewer: null,
      stuffEdit: null,

      stuffNew: {},
      strongEntity: false,
      nameSchema: '',

      pagination: { pages: 0, rowsPerPage: 1000000000, page: 1 },

      options: {
        mode: "code",
        mainMenuBar: false,
      },
    };
  },
  methods: {
    convertDate(date) {
      return new Date(date)
        .toLocaleString('pt-BR')
        .slice(0, 16);
    },
    viewerDialog(item) {
      this.schemaObject = item;
      this.showViewer = true;
      this.stuffViewer = JSON.parse(item.jsonValue);
    },
    editDialog(item) {
      this.schemaObject = item;
      this.showEdit = true;
      this.stuffEdit = JSON.parse(item.jsonValue);
    },
    newDialog() {
      this.showNew = true;
    },
    async postSchemaJson() {
      let name = this.nameSchema;
      let strongEntity = this.strongEntity;
      let stuffNew = this.stuffNew;
      let res = await http.post(`Schema?name=${name}&strongEntity=${strongEntity}`, stuffNew);
      if (res.data.success) {
        this.notifySuccess('Schema Cadastrado!');
        return this.showNew = false;
      } else if (!res.data.success) {
        this.notifyError(res);
      }
    },
    async putSchemaJson() {
      let name = this.schemaObject.name;
      let strongEntity = this.schemaObject.strongEntity;
      let stuffEdit = this.stuffEdit;
      let res = await http.put(`Schema?name=${name}&strongEntity=${strongEntity}`, stuffEdit);
      if (res.data.success) {
        this.notifySuccess('Schema Alterado!');
        return this.showEdit = false;
      } else if (!res.data.success) {
        this.notifyError(res);
      }
    },
    async deleteSchema(item) {
      let itemId = item.id;
      return await http.delete('Schema', itemId);
    },
    closeDialog() {
      if (this.showViewer) this.showViewer = false;
      if (this.showEdit) this.showEdit = false;
      if (this.showNew) this.showNew = false;
    },
  },
};
