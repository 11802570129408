<template>
  <div>
    <v-text-field
      outlined
      v-model="search"
      class="mb-5"
      append-icon="mdi-find-replace"
      label="Pesquisar"
      single-line
      hide-details
    ></v-text-field>

    <v-col class="text-right">
      <v-btn color="secondary" @click="newDialog"> Novo Schema </v-btn>
    </v-col>
    <v-data-table
      :headers="headers"
      :items="tableSchemas"
      :options.sync="pagination"
      :items-per-page="pagination.rowsPerPage"
      hide-default-footer
      class="elevation-1"
      :search="search"
      :custom-sort="customSort"
    >
      <template v-slot:[`item.actions`]="{ item }">
        <v-menu bottom left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="secondary" icon v-bind="attrs" v-on="on">
              <v-icon>mdi-menu</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item link @click="viewerDialog(item)">
              <v-list-item-icon>
                <v-icon color="secondary" v-text="'mdi-open-in-new'"></v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title v-text="'Ver JSON'"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item link @click="editDialog(item)">
              <v-list-item-icon>
                <v-icon color="secondary" v-text="'mdi-code-json'"></v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title v-text="'Editar JSON'"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-divider></v-divider>
            <v-list-item link @click="deleteAtualiza(item)">
              <v-list-item-icon>
                <v-icon
                  color="secondary"
                  v-text="'mdi-trash-can-outline'"
                ></v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title v-text="'Delete Schema'"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
      <template slot="no-data">
        <v-alert color="orange" class="mt-4" type="error"
          >Desculpe, você não possui Schemas! :(
        </v-alert>
      </template>
    </v-data-table>
    <v-dialog v-model="showViewer" scrollable max-width="950px">
      <v-card>
        <v-card-actions>
          <v-card-title>{{
            this.schemaObject && this.schemaObject.name
          }}</v-card-title>
          <v-spacer></v-spacer>
          <v-btn text fab color="red" @click="closeDialog()">
            <v-icon large>mdi-close</v-icon>
          </v-btn>
        </v-card-actions>
        <div>
          <json-viewer
            :value="stuffViewer"
            :expand-depth="5"
            copyable
          ></json-viewer>
        </div>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showEdit" scrollable max-width="950px">
      <v-card>
        <v-card-actions>
          <v-card-title>{{
            this.schemaObject && this.schemaObject.name
          }}</v-card-title>
          <v-spacer></v-spacer>
          <v-btn text fab color="red" @click="closeDialog()">
            <v-icon large>mdi-close</v-icon>
          </v-btn>
        </v-card-actions>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="success" @click="putAtualiza(), getSchemas">
            Aplicar
          </v-btn>
        </v-card-actions>
        <div>
          <v-jsoneditor
            v-model="stuffEdit"
            :options="options"
            height="500px"
          ></v-jsoneditor>
        </div>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showNew" scrollable max-width="950px">
      <v-card>
        <v-card-actions>
          <v-row class="mt-2">
            <v-text-field
              label="Name"
              placeholder="Nome do Schema (Não pode ser repetido)"
              outlined
              v-model="nameSchema"
              class="mr-5"
            ></v-text-field>
            <v-checkbox
              v-model="strongEntity"
              label="StrongEntity"
            ></v-checkbox>

            <v-spacer></v-spacer>
            <v-btn text fab color="red" @click="closeDialog()">
              <v-icon large>mdi-close</v-icon>
            </v-btn>
          </v-row>
        </v-card-actions>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="success" @click="postAtualiza(), getSchemas">
            Aplicar
          </v-btn>
        </v-card-actions>
        <div>
          <v-jsoneditor
            v-model="stuffNew"
            :options="options"
            height="500px"
          ></v-jsoneditor>
        </div>
      </v-card>
    </v-dialog>
    <v-pagination
      @input="paginationChangeHandler"
      v-model="pagination.page"
      :length="pagination.pages"
      circle
    ></v-pagination>
  </div>
</template>
<script>
import schemasMainMixin from '../../../mixins/tables/Schemas/schemasMainMixin';

import { HEAD_TABLE } from './headTableConfig';
export default {
  mixins: [schemasMainMixin],
  data() {
    return {
      headers: HEAD_TABLE,
    };
  },
  mounted() {
    this.$nextTick(this.getSchemas);
  },
};
</script>
