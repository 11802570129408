<template>
  <v-container>
    <v-row class="text-center">
      <v-col cols="12">
        <schema-table />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import schemaTable from '../components/tables/Schemas/schemaTable.vue';
export default {
  components: { schemaTable },
  name: 'Schemas',
};
</script>
